.MuiDigitalClock-item.Mui-disabled{
    display: none;
}

.MuiDigitalClock-item{
font-size: 14px !important;
font-weight: 400 !important;
line-height: 14px !important;
letter-spacing: 0em!important;

}
.MuiPickersLayout-actionBar{
    display: none !important;
}

.MuiDigitalClock-root{
      scrollbar-color: #6EC1E4;
      scrollbar-width: thin;
  
     
}
.MuiDigitalClock-root::-webkit-scrollbar {
        width: 2px !important
      }
      .MuiDigitalClock-root::-webkit-scrollbar-track {
        background:#6EC1E4
      }
      .MuiDigitalClock-root::-webkit-scrollbar-thumb {
        background: #888 !important
      }