#video {
    position: fixed;
    z-index: -1;
    width: 25%;
}

.one {
    width: 53% !important;
    height: 100vh;
    position: relative;
    transform: rotate(20.24deg);
    left: -38px;
    margin-left: -10px;
}

.two {
    width: 53% !important;
    height: 100vh;
    position: relative;
    transform: rotate(20.24deg);
        margin-left: -10px;
}

.video-background {
    width: 106%;
    height: 125%;
    position: relative;
    left: 0;
    top: -94px;
    z-index: -1;
    object-fit: cover;
}

.authenticationVideoContainer {
    display: flex;
    position: relative;
    left: -100px;

}

#authContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.nav-menu-tab{
        width: 68px !important;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@media screen and (min-width:1600px) {
    .authenticationVideoContainer {
        left: -160px;
    }
    .video-background{
        top:-160px;
    }
    #dashboardLayoutContainer{
        max-width: 1536px;
    display: flex;
    margin-left:auto ;
    margin-right: auto;
    }
}
